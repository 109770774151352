import React from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
// import splitting from 'splitting';

// home experiences
const Artist = styled.a``;

export default function LineupSingle({
  img,
  artist,
  to,
  transition,
  direction,
  bg,
  where,
}) {
  return (
    <div data-img={img} className="menu__item">
      <span className="menu__item-text">
        <span className="menu__item-textinner">
          <AniLink
            to={to}
            cover
            direction="down"
            activeClassName="active"
            bg={bg}
          >
            {artist}
          </AniLink>
        </span>
      </span>
      <span className="menu__item-sub">{where}</span>
    </div>
  );
}
